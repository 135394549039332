// --- 遊戲開盤 ---
// 早盤
export const GAME_EARLY_TYPE = 0;
// 今日
export const GAME_TODAY_TYPE = 1;
// 滾球/走地
export const GAME_PLAYING_TYPE = 2;
// 未開賽
export const GAME_NOT_PLAYING_TYPE = 3;

// --- 遊戲球種 ---
// 足球
export const GAME_FOOTBALL_CAT = 1;
// 美足/橄欖球
export const GAME_RUGBY_CAT = 5;
// 桌球/乒乓球
export const GAME_PING_PONG_CAT = 21;
// 羽毛球
export const GAME_BADMINTON_CAT = 22;
// 世界盃&歐冠杯
export const GAME_FOOTBALL_CUP_CAT = 31;
// 網球
export const GAME_TENNIS_CAT = 55;
// 賽狗&賽馬
export const GAME_HORSE_DOG_CAT = 72;
// 冰球
export const GAME_HOCKEY_CAT = 82;
// 彩球
export const GAME_LOTTERY_CAT = 83;
// 指數
export const GAME_INDEX_CAT = 84;
// 電競
export const GAME_PCGAME_CAT = 85;
// 冠軍
export const GAME_CHAMPION_CAT = 86;
// 真人
export const GAME_LIVE_CAT = 87;
// 其它
export const GAME_OTHERS_CAT = 91;
// 棒球
export const GAME_BASEBALL_CAT = 101;
// 籃球
export const GAME_BASKETBALL_CAT = 102;
// 超吉8
export const GAME_SUPER8_CAT = 110;
// 熱門賽事
export const GAME_HOT_CAT = 888888;
// 我的最愛
export const GAME_FAVORITE_CAT = 999999;
// 足球
export const GAME_FOOTBALL_CATS = [GAME_FOOTBALL_CAT, GAME_FOOTBALL_CUP_CAT];

// --- 遊戲群組 ---
// 早盤全場
export const GAME_EARLY_FULL_GROUP = 0;
// 早盤上半
export const GAME_EARLY_HALF_GROUP = 1;
// 今日全場
export const GAME_TODAY_FULL_GROUP = 10;
// 今日上半
export const GAME_TODAY_HALF_GROUP = 11;
// 滾球全場
export const GAME_PLAYING_FULL_GROUP = 20;
// 滾球上半
export const GAME_PLAYING_HALF_GROUP = 21;
// 第1節
export const GAME_FIRST_SECTION_GROUP = 31;
// 第2節
export const GAME_SECOND_SECTION_GROUP = 32;
// 第3節
export const GAME_THIRD_SECTION_GROUP = 33;
// 第4節
export const GAME_FOURTH_SECTION_GROUP = 34;
// 下半
export const GAME_SECOND_HALF_GROUP = 35;
// 無分類
export const GAME_NO_GROUP = 128;
// 全場
export const GAME_FULL_GROUPS = [
  GAME_EARLY_FULL_GROUP,
  GAME_TODAY_FULL_GROUP,
  GAME_PLAYING_FULL_GROUP,
];
// 上半
export const GAME_HALF_GROUPS = [
  GAME_EARLY_HALF_GROUP,
  GAME_TODAY_HALF_GROUP,
  GAME_PLAYING_HALF_GROUP,
];

// --- 遊戲玩法 ---
// 讓分: 101 讓分, 103 台盤讓分, 121 局數讓分, 124 盤數讓分, 129 總分讓分
// 103: 搶首 10 分, 搶首 15 分, 搶首 20 分
export const GAME_HANDICAP_WAGERS = [101, 103, 121, 124, 129];
// 大小: 102 大小, 104 台盤大小, 109 棒球大小, 122 局數大小, 125 總分大小, 131 單隊總分大小, 134 盤數大小
export const GAME_BIG_SMALL_WAGERS = [102, 104, 109, 122, 125, 131, 134];
// 單雙: 105 單雙, 113 足球單雙, 126 局數單雙, 128 總分單雙, 132 單隊總分單雙
export const GAME_ODD_EVEN_WAGERS = [105, 113, 126, 128, 132];
// 一輸二贏
export const ONE_LOSE_TWO_WIN_WAGERS = [106];
// 獨贏: 110 足球獨贏, 111 獨贏, 123 局數獨贏, 127 盤數獨贏
export const GAME_WIN_LOSE_WAGERS = [110, 111, 123, 127];
// 波膽: 112 波膽
export const GAME_BOLD_WAGERS = [112];
// 總入球: 115 總入球
export const GAME_TOTAL_WAGERS = [115];
// 半全場: 116 半全場
export const GAME_HALF_FULL_WAGERS = [116];
// 搶首分: 117 搶首分
export const GAME_FIRST_SCORE_WAGERS = [117];
// 搶尾分: 118 搶尾分
export const GAME_LAST_SCORE_WAGERS = [118];
// 入球數: 119 入球數
export const GAME_TOTAL_BALL_WAGERS = [119];
// 反波膽: 120 反波膽
export const GAME_ANTI_BOLD_WAGERS = [120];
// 單隊總分: 131 單隊總分大小, 132 單隊總分單雙
export const GAME_ONE_TEAM_SCORE_WAGERS = [131, 132];
// 冠軍
export const GAME_CHAMPION_WAGERS = [133];

// --- 遊戲 ---
// 遊戲可投注狀態
export const GAME_OK_STATUS = 1;

// --- 賽事 ---
// 賽事可投注狀態
export const EVENT_OK_STATUS = 1;

// --- 賠率 ---
// 賠率精確度: 小數點後3位
export const ODDS_PRECISION = 3;
// 賠率最小值: 高於此賠率才可投注
export const ODDS_MIN = 0.01;

// --- 投注 ---
// 一般投注
export const BET_NORMAL_TYPE = 1;
// 複式過關投注
export const BET_COMBO_PARLAY_TYPE = 97;
// 過關投注
export const BET_SUPER8_PARLAY_TYPE = 98;
// 過關投注
export const BET_PARLAYL_TYPE = 99;

// --- 串關 ---
// 最大串關數
export const PARLAYS_MAX = 10;
// 最大可贏金額: 200萬
export const PARLAYS_WIN_MAX = 2000000;

// --- 注單 ---
// 有效注單
export const TICKET_OK_STATUS = 'Y';
// 無效注單
export const TICKET_NOT_OK_STATUS_LIST = ['D', 'V'];

// 電腦版
export const APP_PC_MODE = 'pc';
// 手機版
export const APP_MOBILE_MODE = 'mobile';

// 當前版本
export const APP_MODE = process.env.VUE_APP_UI;
// 電腦版
export const PC_APP = APP_MODE === APP_PC_MODE;
// 手機版
export const MOBILE_APP = APP_MODE === APP_MOBILE_MODE;

// 版號: 更新日期 + 當日更新次序
export const APP_VERSION = '20250122';

// --- 站台 ---
// 娛樂城站台
export const SITE_CASINO_LABEL = 's1';

export const isSupportFullscreen = (() => {
  return !navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
})();
